import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment, EnvironmentService } from '@galaxy/core';
import {
  GetGoogleProfileResponseInterface,
  GetLinkedinProfileResponseInterface,
  GetSelfSignUpStatusResponseInterface,
  IsEmailInUseRequestInterface,
  IsEmailInUseResponse,
  PartnerSignupApiService,
  SignUpRequestInterface,
  Status,
} from '@galaxy/partner';
import { ProductAnalyticsService } from '@vendasta/product-analytics';
import { LocalStorageService } from '@vendasta/uikit';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, expand, filter, map, take, tap, switchMap } from 'rxjs/operators';
import { IAMService, UserIdentifier } from '@vendasta/iamv2';
import { GetMicrosoftProfileResponseInterface } from '@vendasta/partner';

const NEXT_URL_KEY = 'NEXT_URL';

export interface AccessTokenEmail {
  accessToken: string;
  email: string;
}

export interface IDTokenEmail {
  idToken: string;
  email: string;
}

export interface GoogleProfileRequest {
  googleCode: string;
}

export interface PartnerBase {
  websiteUrl?: string;
  websiteOptOutFlag?: boolean;
  partnerName?: string;
  phoneNumber?: string;
  numberOfEmployees?: string;
  leadSource?: string;
  leadDescription?: string;
  address?: string;
  city?: string;
  country?: string;
  state?: string;
  zipcode?: string;
  conversionPoint: string;
  conquerlocalAcademyVisitorId: string;
  tags: string[];
  recaptchaResponse?: string;
  recaptchaV3Token?: string;
  currency?: string;
  stripeToken?: string;
}

export interface PartnerSignUpRequest extends PartnerBase {
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
}

export interface PartnerGoogleSignUpRequest extends PartnerBase {
  googleToken: string;
  nextUrl?: string;
}

export interface PartnerMicrosoftSignUpRequest extends PartnerBase {
  microsoftToken: string;
  nextUrl?: string;
}

export interface PartnerLinkedInSignUpRequest extends PartnerBase {
  linkedinToken: string;
  nextUrl?: string;
}
// random choice of whether to include two step form
export const twoFormStepEnabled: boolean = Math.random() > 0.5;

@Injectable({ providedIn: 'root' })
export class SignUpService {
  constructor(
    private localStorageService: LocalStorageService,
    private partnerSignupApiService: PartnerSignupApiService,
    private environmentService: EnvironmentService,
    private readonly trackingService: ProductAnalyticsService,
    private readonly iamService: IAMService,
  ) {}

  recaptchaSiteKey(): string {
    return '6Ldgw64ZAAAAAOxUtKGnEonV_bNBhRdqwQnoceBB';
  }

  signUp(request: SignUpRequestInterface): Observable<string> {
    const signupCategory = twoFormStepEnabled ? 'SelfSignUp_B' : 'SelfSignUp_A';
    return this.partnerSignupApiService.signUp(request).pipe(
      switchMap(() => this.pollCreatePartnerStatus(request.idempotentKey)),
      tap((_) => {
        this.trackingService.trackEvent(signupCategory + ':signup-completed', signupCategory, 'signup-completed');
      }),
    );
  }

  login(partnerId: string, email: string, password: string, nextUrl?: string): Observable<string> {
    return this.partnerSignupApiService
      .login({
        partnerId,
        emailPasswordCredential: {
          email,
          password,
        },
        nextUrl,
      })
      .pipe(map((response) => response.nextUrl));
  }

  storeNextUrl(nextUrl: string): void {
    this.localStorageService.set(NEXT_URL_KEY, nextUrl);
  }

  saveToLocalStorage(key: string, value: string): void {
    this.localStorageService.set(key, value);
  }

  readFromLocalStorage(key: string): string {
    return this.localStorageService.get(key);
  }

  isEmailInUse(
    email: string,
    recaptchaToken: string,
    isRecaptchaV3: boolean,
  ): Observable<{ emailInUse: boolean; persona: string }> {
    const emailInUseRequest = {
      email: email,
      recaptchaV3Token: isRecaptchaV3 ? recaptchaToken : null,
      recaptchaV2Token: isRecaptchaV3 ? null : recaptchaToken,
      visitorId: this.trackingService.getVisitorId(),
    } as IsEmailInUseRequestInterface;
    return this.partnerSignupApiService.isEmailInUse(emailInUseRequest).pipe(
      map((res: IsEmailInUseResponse) => {
        const subjects = res.subjects || [];
        let persona = '';
        if (subjects.includes('partner')) {
          persona = 'partner';
        } else if (subjects.includes('digital_agent')) {
          persona = 'digital_agent';
        } else if (subjects.includes('smb')) {
          persona = 'smb';
        }
        return {
          emailInUse: !!subjects.length,
          persona: persona,
        };
      }),
      catchError((err) => {
        // Failed pre-condition indicates a captcha error, let the component handle this
        if (err?.error?.code === 9 || err?.error?.code === 412) {
          throw err;
        }
        console.error('failed isEmailInUse', err);
        return of({ emailInUse: false, persona: 'unknown' });
      }),
    );
  }

  isEmailDisposable(email: string, recaptchaToken: string, isRecaptchaV3: boolean): Observable<boolean> {
    const emailDisposableRequest = {
      email: email,
      recaptchaV3Token: isRecaptchaV3 ? recaptchaToken : null,
      recaptchaV2Token: isRecaptchaV3 ? null : recaptchaToken,
    };
    return this.partnerSignupApiService.isEmailDisposable(emailDisposableRequest).pipe(
      map((res) => res.isDisposable),
      catchError((err) => {
        console.error('failed isEmailDisposable', err);
        return of(false);
      }),
    );
  }

  redirectToLinkedinAuth(state?: string): void {
    const httpParams = new HttpParams()
      .set('client_id', this.getLinkedinClientId())
      .set('redirect_uri', this.getLinkedinRedirectUrl())
      .set('response_type', 'code')
      .set('scope', 'r_liteprofile r_emailaddress')
      .set('state', state);
    window.location.href = `${'https://www.linkedin.com/oauth/v2/authorization'}?${httpParams.toString()}`;
  }

  redirectToMicrosoftAuth(state?: string): void {
    let httpParams = new HttpParams()
      .set('client_id', this.getMicrosoftClientId())
      .set('response_type', 'code')
      .set('redirect_uri', this.getMicrosoftRedirectUrl())
      .set('response_mode', 'query')
      .set('scope', 'openid email profile');

    if (state) {
      httpParams = httpParams.set('state', state);
    }

    window.location.href = `${'https://login.microsoftonline.com/common/oauth2/v2.0/authorize'}?${httpParams.toString()}`;
  }

  redirectToGoogleAuth(state?: string): void {
    let httpParams = new HttpParams()
      .set('client_id', this.getGoogleClientId())
      .set('redirect_uri', this.getGoogleRedirectUrl())
      .set('response_type', 'code')
      .set('scope', 'email openid profile')
      .set('ux_mode', 'redirect');

    if (state) {
      httpParams = httpParams.set('state', state);
    }

    window.location.href = `${'https://accounts.google.com/o/oauth2/v2/auth'}?${httpParams.toString()}`;
  }

  pollCreatePartnerStatus(idempotentKey: string): Observable<string> {
    return this.partnerSignupApiService.getSelfSignUpStatus({ idempotentKey }).pipe(
      tap(() => this.trackingService.trackEvent('SelfSignUp:wait-for-signup', 'SelfSignUp', 'wait-for-signup')),
      expand((status: GetSelfSignUpStatusResponseInterface) => {
        if (status.status === Status.STATUS_IN_PROGRESS) {
          return this.partnerSignupApiService.getSelfSignUpStatus({ idempotentKey });
        }
        return EMPTY;
      }),
      filter((status: GetSelfSignUpStatusResponseInterface) => status.status !== Status.STATUS_IN_PROGRESS),
      map((status: GetSelfSignUpStatusResponseInterface) => status.partnerId),
      take(1),
    );
  }

  getGoogleProfile(googleCode: string): Observable<AccessTokenEmail> {
    return this.partnerSignupApiService
      .getGoogleProfile({
        googleCode,
        redirectUrl: this.getGoogleRedirectUrl(),
      })
      .pipe(
        take(1),
        map((resp: GetGoogleProfileResponseInterface) => ({
          accessToken: resp.token,
          email: resp.email,
        })),
      );
  }

  googleSignin(googleToken: string, partnerId: string, nextUrl: string): Observable<string> {
    return this.partnerSignupApiService
      .login({
        tokenCredential: {
          googleToken,
        },
        partnerId,
        nextUrl,
      })
      .pipe(map((resp) => resp.nextUrl));
  }

  getGoogleClientId(): string {
    const env = this.environmentService.getEnvironment();

    if (env === Environment.DEMO) {
      return '234031817538-tpvjevbkb5hh015dqetpk4rp6rer6cnq.apps.googleusercontent.com';
    } else if (env === Environment.PROD) {
      return '113661895208-vfkcm0d2n0tmgp8onqil2lsunrt9mg95.apps.googleusercontent.com';
    }
  }

  getGoogleRedirectUrl(): string {
    switch (this.environmentService.getEnvironment()) {
      case Environment.PROD:
        return 'https://signup.vendasta.com/';
      default:
        return 'https://partner-demo.vendasta-internal.com/';
    }
  }

  getMicrosoftProfile(microsoftCode: string): Observable<IDTokenEmail> {
    return this.partnerSignupApiService
      .getMicrosoftProfile({
        authorizationCode: microsoftCode,
        redirectUrl: this.getMicrosoftRedirectUrl(),
      })
      .pipe(
        take(1),
        map((resp: GetMicrosoftProfileResponseInterface) => ({
          idToken: resp.idToken,
          email: resp.email,
        })),
      );
  }

  microsoftSignin(microsoftToken: string, partnerId: string, nextUrl: string): Observable<string> {
    return this.partnerSignupApiService
      .login({
        microsoftCredential: {
          microsoftIdToken: microsoftToken,
        },
        partnerId,
        nextUrl,
      })
      .pipe(map((resp) => resp.nextUrl));
  }

  getMicrosoftClientId(): string {
    return '04483de7-a8f4-405a-9959-1500557e711a';
  }

  getMicrosoftRedirectUrl(): string {
    switch (this.environmentService.getEnvironment()) {
      case Environment.PROD:
        return 'https://signup.vendasta.com/';
      default:
        return 'https://partner-demo.vendasta-internal.com/';
    }
  }

  getLinkedinRedirectUrl(): string {
    switch (this.environmentService.getEnvironment()) {
      case Environment.LOCAL:
        return 'https://localhost:4200';
      case Environment.DEMO:
        return 'https://partner-demo.vendasta-internal.com/';
      default:
        return 'https://signup.vendasta.com/';
    }
  }

  linkedInSignin(linkedinAccessToken: string, partnerId: string, nextUrl: string): Observable<string> {
    return this.partnerSignupApiService
      .login({
        linkedinCredential: {
          linkedinAccessToken,
        },
        partnerId,
        nextUrl,
      })
      .pipe(map((resp) => resp.nextUrl));
  }

  getLinkedInProfile(authorizationCode: string): Observable<AccessTokenEmail> {
    return this.partnerSignupApiService
      .getLinkedInProfile({
        authorizationCode,
        redirectUrl: this.getLinkedinRedirectUrl(),
      })
      .pipe(
        take(1),
        map((resp: GetLinkedinProfileResponseInterface) => ({
          accessToken: resp.token,
          email: resp.email,
        })),
      );
  }

  private getLinkedinClientId(): string {
    switch (this.environmentService.getEnvironment()) {
      case Environment.PROD:
        return '86w012lacppqp4';
      default:
        return '78nbwuhw0rf42b'; // Demo app
    }
  }

  sendEmailVerificationEmail(
    email: string,
    partnerID: string,
    nextURL: string,
    nextURLText: string,
    token: string,
  ): Observable<boolean> {
    return this.iamService
      .sendEmailVerification(
        new UserIdentifier({
          namespacedEmail: {
            email: email,
            namespace: partnerID,
          },
        }),
        'VMF',
        nextURL,
        nextURLText,
        token,
      )
      .pipe(map(() => true));
  }
}
