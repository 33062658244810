import { LayoutModule } from '@angular/cdk/layout';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { SessionService } from '@galaxy/core';
import { LexiconModule } from '@galaxy/lexicon';
import { FeatureFlagService } from '@galaxy/partner';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StripeService } from '@vendasta/billing-ui';
import { GalaxyButtonLoadingIndicatorModule } from '@vendasta/galaxy/button-loading-indicator';
import { GalaxyWrapModule } from '@vendasta/galaxy/galaxy-wrap';
import { GalaxyI18NModule } from '@vendasta/galaxy/i18n';
import { GalaxyInputModule } from '@vendasta/galaxy/input';
import { GalaxySnackbarModule } from '@vendasta/galaxy/snackbar-service';
import { GalaxyDefaultProviderOverrides } from '@vendasta/galaxy/provider-default-overrides';
import { ProductAnalyticsModule, ProductAnalyticsService } from '@vendasta/product-analytics';
import { LocalStorageService, VaStencilsModule } from '@vendasta/uikit';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaModule, RecaptchaV3Module } from 'ng-recaptcha';
import { of } from 'rxjs';

import baseTranslation from '../assets/i18n/en_devel.json';
import { AppComponent } from './app.component';
import { AutocompleteService } from './autocomplete.service';
import { WEBLATE_COMPONENT_NAME } from './constants';
import { CoreModule as SelfSignupCoreModule } from './core/core.module';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { SignUpService } from './sign-up-form/sign-up-form.service';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import { SignUpStepOneComponent } from './sign-up-form/signup-steps/sign-up-step-one.component';
import { SignUpStepTwoFormComponent } from './sign-up-form/signup-steps/sign-up-step-two-form.component';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';

const materialModules = [
  MatProgressBarModule,
  MatSelectModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatOptionModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatCardModule,
  MatIconModule,
  MatRadioButton,
  MatRadioGroup,
];

const routes: Routes = [
  { path: '', component: AppComponent, pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  exports: [AppComponent, SignUpFormComponent, SignUpStepOneComponent, SignUpStepTwoFormComponent],
  declarations: [AppComponent, SignUpFormComponent, SignUpStepOneComponent, SignUpStepTwoFormComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
    GalaxyI18NModule,
    GalaxyWrapModule,
    CommonModule,
    ReactiveFormsModule,
    ...materialModules,
    BrowserAnimationsModule,
    RouterModule,
    MatAutocompleteModule,
    TranslateModule,
    LexiconModule.forRoot({
      componentName: WEBLATE_COMPONENT_NAME,
      baseTranslation: baseTranslation,
    }),
    FormsModule,
    GalaxyInputModule,
    SelfSignupCoreModule,
    VaStencilsModule,
    FormsModule,
    LayoutModule,
    ProductAnalyticsModule,
    RecaptchaV3Module,
    RecaptchaModule,
    GalaxyButtonLoadingIndicatorModule,
    GoogleMapsModule,
    GalaxySnackbarModule,
    GalaxyFormFieldModule,
  ],
  providers: [
    ...GalaxyDefaultProviderOverrides,
    AutocompleteService,
    SignUpService,
    FeatureFlagService,
    TranslateService,
    ProductAnalyticsService,
    LocalStorageService,
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6Ldb04YbAAAAAK5EC-OBQDv2J9IwihRJdvKPBnIA',
    },
    {
      provide: 'PARTNER_ID',
      useFactory: () => of('VMF'),
      deps: [],
    },
    StripeService,
    SessionService,
    provideHttpClient(
      withInterceptorsFromDi(),
      withXsrfConfiguration({
        headerName: 'x-blast-shield',
        cookieName: 'x-blast-shield',
      }),
    ),
  ],
})
export class AppModule {}
