export const CL_LINK = 'www.conquerlocal.com';
export const CL_ACADEMY_LINK = 'academy.conquerlocal.com';
export const CL_COMMUNITY_STG_LINK = 'conquer-local-academy.websitepro-staging.com';
export const CL_COMMUNITY_LINK = 'community.conquerlocal.com';
export const AFFILIATE_PROD_LINK = 'partners.vendasta.com/affiliates';
export const AFFILIATE_DEMO_LINK = 'partner-central-demo.appspot.com/affiliates';
export const USER_ONBOARDING = '/user-onboarding';

export const NEXT_URLS = [
  CL_LINK,
  CL_ACADEMY_LINK,
  CL_COMMUNITY_STG_LINK,
  CL_COMMUNITY_LINK,
  AFFILIATE_PROD_LINK,
  AFFILIATE_DEMO_LINK,
  USER_ONBOARDING,
];
