import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router, UrlSerializer } from '@angular/router';
import { emailValidator as vendastaEmailValidator } from '@vendasta/forms';
import { SnackbarService } from '@vendasta/galaxy/snackbar-service';
import {
  GOOGLE_PRIVACY_POLICY_URL,
  GOOGLE_TERMS_OF_SERVICE_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
} from '../constants';
import { twoFormStepEnabled } from './sign-up-form.service';
import { PartnerOrigin, SignUpRequestInterface } from '@galaxy/partner';
import { TranslateService } from '@ngx-translate/core';
import { ProductAnalyticsService } from '@vendasta/product-analytics';
import { SSOService } from '@vendasta/sso';
import jwt_decode from 'jwt-decode';
import { RecaptchaComponent, ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject, combineLatest, Observable, of, Subject, timer } from 'rxjs';
import { catchError, distinctUntilChanged, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { DropdownItem } from '../autocomplete.service';
import { CookieService } from '../core';
import { SSOState } from '../core/sso-state';
import {
  ACCOUNT_INFO_STEP_ONE,
  ACCOUNT_INFO_STEP_TWO,
  CurrentStepService,
  SIGNUP_LOADING_SCREEN,
  SIGNUP_PROVIDER_STEP,
  // USE_CASE_QUESTION_STEP,
  VERIFY_EMAIL_STEP,
} from '../currentstep.service';
import { CustomContent, CustomContentValue } from '../custom-content';
import { NEXT_URLS } from '../next-urls';
import { SignUpService, AccessTokenEmail, IDTokenEmail } from './sign-up-form.service';
import { Environment, EnvironmentService } from '@galaxy/core';

declare let window;

export interface CompanyAddressInfo {
  address: string;
  city: string;
  zip: string;
  country: string;
  country_short: string;
  region: string;
  region_short: string;
  name: string;
  place_id: string;
}

const SIGNUP_TYPES = {
  google: 'google',
  microsoft: 'microsoft',
  linkedin: 'linkedin',
};

const TAGS_BY_VARIANT_TYPE = {
  hackerone: 'hackerone',
};

// const USE_CASE_QUESTION = {
//   leads: 'Acquire Leads',
//   scale: 'Scale Operations Efficiently',
// };

const noVendastaEmails = (control: AbstractControl): ValidationErrors => {
  let isVendastaEmail = false;
  if (control.value) {
    isVendastaEmail = control?.value?.includes('@vendasta.com') || false;
  }
  return isVendastaEmail ? { forbiddenEmail: true } : null;
};

const mapCountryToCurrency = (country: string): string => {
  switch (country) {
    case 'AU':
    case 'NF':
    case 'NR':
    case 'NZ':
      return 'AUD';
    case 'CA':
      return 'CAD';
    default:
      return 'USD';
  }
};

const getCookieStartingWith = (cookieName: string): string => {
  const cookieValues = document.cookie.split(';');
  for (let i = 0; i < cookieValues.length; i++) {
    let cookieValue = cookieValues[i];
    while (cookieValue.charAt(0) === ' ') {
      cookieValue = cookieValue.substring(1);
    }
    if (cookieValue.indexOf(cookieName) === 0) {
      const cookieParts = cookieValue.split('=');
      if (cookieParts.length !== 2) {
        return '';
      }
      return cookieParts[1];
    }
  }
  return '';
};

const CONVERSION_POINT_KEY = 'CONVERSION_POINT';
const CONQUER_LOCAL_VISITOR_ID_KEY = 'CONQUER_LOCAL_EXTERNAL_VISITOR_ID';
const PROSPECT_TAGS_KEY = 'prospect_tags';
const SIGNUP_REFERRER_ID_KEY = 'SIGNUP_REFERRER_ID';

const TYPING_DEBOUNCE_MS = 500;

@Component({
  selector: 'app-sign-up-form',
  styleUrls: ['./sign-up-form.component.scss'],
  templateUrl: './sign-up-form.component.html',
})
export class SignUpFormComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() loginURL: string;

  twoFormStepEnabled: boolean = twoFormStepEnabled;

  SIGNUP_PROVIDER_STEP = SIGNUP_PROVIDER_STEP;
  ACCOUNT_INFO_STEP_ONE = ACCOUNT_INFO_STEP_ONE;
  ACCOUNT_INFO_STEP_TWO = ACCOUNT_INFO_STEP_TWO;
  VERIFY_EMAIL_STEP = VERIFY_EMAIL_STEP;
  SIGNUP_LOADING_SCREEN = SIGNUP_LOADING_SCREEN;
  // USE_CASE_QUESTION_STEP = USE_CASE_QUESTION_STEP;
  TERMS_OF_SERVICE_URL = TERMS_OF_SERVICE_URL;
  PRIVACY_POLICY_URL = PRIVACY_POLICY_URL;
  GOOGLE_TERMS_OF_SERVICE_URL = GOOGLE_TERMS_OF_SERVICE_URL;
  GOOGLE_PRIVACY_POLICY_URL = GOOGLE_PRIVACY_POLICY_URL;

  hide = true;
  showFirstStep = true;
  loading = false;
  resendingVerificationEmail = false;
  continuingToPlatform = false;
  leadSource = '';
  leadDescription = '';
  conversionPoint = '';
  conquerLocalVisitorID = '';
  prospectTags = '';
  googleOptionSelected$$ = new BehaviorSubject<boolean>(false);
  microsoftOptionSelected$$ = new BehaviorSubject<boolean>(false);
  linkedOptionSelected$$ = new BehaviorSubject<boolean>(false);
  isExistingAccountGroup$$ = new BehaviorSubject<boolean>(false);
  isGoogleOneTap = false;
  isMicrosoftOneTap = false;
  signupType = '';
  renderRecaptchaV2 = false;
  recaptchaV2isEmailInUse = false;
  emailSentTo = '';
  createdPartnerId = '';
  password = '';

  nextUrl = '';
  nextUrlIsValid = false;

  recaptchaSiteKey: string;

  companyAddressDetails: CompanyAddressInfo = {} as CompanyAddressInfo;
  locations: DropdownItem[];
  txtQueryChanged: Subject<string> = new Subject<string>();
  stripeToken: string;

  form: UntypedFormGroup;
  formChangingRequirements$$ = new BehaviorSubject(false);
  formFieldEnabled: { [key: string]: BehaviorSubject<boolean> } = {
    firstName: new BehaviorSubject<boolean>(true),
    lastName: new BehaviorSubject<boolean>(true),
    password: new BehaviorSubject<boolean>(true),
    companyName: new BehaviorSubject<boolean>(true),
    numberOfEmployees: new BehaviorSubject<boolean>(true),
    countrySearchControl: new BehaviorSubject<boolean>(true),
    phoneNumber: new BehaviorSubject<boolean>(true),
    websiteUrl: new BehaviorSubject<boolean>(true),
  };
  passwordValidators = [
    {
      validatorFn: Validators.minLength(8),
      errorMessage: this.translateService.instant('SIGNUP.VALIDATION.PASSWORD_MIN_LENGTH'),
    },
  ];

  googleToken: string;
  microsoftToken: string;
  linkedInToken: string;

  params: Params;

  useCaseQuestionControl: UntypedFormControl = new UntypedFormControl('');
  countryControl: UntypedFormControl = new UntypedFormControl('');
  destroyed$$ = new Subject<void>();

  customContent$$ = new BehaviorSubject<CustomContentValue>(CustomContent.getValue(''));
  currentStep$ = this.currentStepService.stepChange$.pipe(map((step) => step?.current));

  @ViewChildren('formContainer') private formContainer: QueryList<ElementRef>;
  @ViewChild('captchaRef') captchaRef: RecaptchaComponent;
  nextURLForEmailVerification$: Observable<string>;

  constructor(
    private snackbarService: SnackbarService,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    private signUpService: SignUpService,
    private zone: NgZone,
    private changeDetector: ChangeDetectorRef,
    private recaptchaV3Service: ReCaptchaV3Service,
    private currentStepService: CurrentStepService,
    private readonly trackingService: ProductAnalyticsService,
    private router: Router,
    private serializer: UrlSerializer,
    private ssoService: SSOService,
    private translateService: TranslateService,
    private environment: EnvironmentService,
  ) {
    this.nextURLForEmailVerification$ = this.ssoService.getEntryUrl('AA', null).pipe(
      take(1),
      map((entryURL) => {
        const nextURL = new URL(entryURL);
        nextURL.searchParams.set('nextUrl', this.getNextURL());
        return nextURL.toString();
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
      takeUntil(this.destroyed$$),
    );
    this.nextURLForEmailVerification$.subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.unsubscribe();
  }

  private buildSignUpRequest(
    recaptchaCode: string,
    isRecaptchaV3: boolean,
    email: string,
    password: string,
  ): SignUpRequestInterface {
    const signUpRequest = {
      acquisitionData: {
        tags: this.prospectTags ? [this.prospectTags] : [],
        origin: PartnerOrigin.PARTNER_ORIGIN_SELF_SIGN_UP,
        visitorIdCookie: this.trackingService.getVisitorId(),
        affiliateId: getCookieStartingWith('growSumoPartnerKey') || getCookieStartingWith('affiliate_id'),
        referrerId: getCookieStartingWith(SIGNUP_REFERRER_ID_KEY),
        conquerLocalAcademyVisitorId: this.conquerLocalVisitorID,
        conversionPoint: this.conversionPoint,
        numberOfEmployees: this.form.get('numberOfEmployees').value,
        //useCase: USE_CASE_QUESTION[this.useCaseQuestionControl.value],
      },
      termsOfService: {
        // Pass default IP until we can refactor terms of service mechanisms.
        browserIpAddress: '127.0.0.1',
        accepted: true,
      },
      recaptchaV2Token: !isRecaptchaV3 ? recaptchaCode : null,
      recaptchaV3Token: isRecaptchaV3 ? recaptchaCode : null,
      billingCurrencyCode: mapCountryToCurrency(this.countryControl.value),
      websiteOptOutFlag: true,
      stripeToken: this.stripeToken,
    } as SignUpRequestInterface;

    let credential;
    let credentialText;
    if (this.googleOptionSelected$$.value) {
      const googleCredential = {
        googleToken: this.googleToken,
      };
      credential = {
        googleToken: googleCredential,
      };
      credentialText = 'GOOGLE-SIGN-UP';
    } else if (this.microsoftOptionSelected$$.value) {
      const microsoftCredential = {
        microsoftIdToken: this.microsoftToken,
      };
      credential = {
        microsoftToken: microsoftCredential,
      };
      credentialText = 'MICROSOFT-SIGN-UP';
    } else if (this.linkedOptionSelected$$.value) {
      const linkedInCredential = {
        linkedinAccessToken: this.linkedInToken || '',
      };
      credential = {
        linkedinToken: linkedInCredential,
      };
      credentialText = 'LINKEDIN-SIGN-UP';
    } else {
      const userCredential = {
        user: {
          firstName: this.form.get('firstName').value,
          lastName: this.form.get('lastName').value,
          email: email,
        },
        password: password,
      };
      credential = {
        user: userCredential,
      };
      credentialText = 'SELF-SIGN-UP';
    }

    let signUpType;
    let signUpTypeText;
    if (this.isExistingAccountGroup$$.value) {
      signUpType = {
        existingAccount: {
          credential,
        },
      };
      signUpTypeText = 'EXISTING';
    } else {
      signUpType = {
        newAccount: {
          credential,
          companyProfile: {
            name: this.getBusinessNameFromForm(),
            address: this.companyAddressDetails.address,
            city: this.companyAddressDetails.city,
            state: this.companyAddressDetails.region_short,
            postalCode: this.companyAddressDetails.zip,
            country: this.companyAddressDetails.country || this.form.get('countrySearchControl').value,
            emailAddress: email,
            websiteAddress: this.form.get('websiteUrl').value,
            phoneNumber: this.form.get('phoneNumber').value,
          },
        },
      };
      signUpTypeText = 'NEW';
    }

    return {
      ...signUpRequest,
      ...signUpType,
      idempotentKey: `${signUpTypeText}-${credentialText}-${uuidv4()}`,
    };
  }

  private changeFormRequirements(): void {
    combineLatest([
      this.googleOptionSelected$$,
      this.microsoftOptionSelected$$,
      this.linkedOptionSelected$$,
      this.isExistingAccountGroup$$,
    ])
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        tap(([googleOption, microsoftOption, linkedOption, isExistingAc]) => {
          this.formChangingRequirements$$.next(true);
          const fieldsWeDontNeed = {
            firstName: false,
            lastName: false,
            password: false,
            companyName: false,
            numberOfEmployees: false,
            countrySearchControl: false,
            phoneNumber: false,
            websiteUrl: false,
          };

          if (googleOption || microsoftOption || linkedOption) {
            fieldsWeDontNeed.firstName = true;
            fieldsWeDontNeed.lastName = true;
            fieldsWeDontNeed.password = true;
          }

          if (isExistingAc) {
            fieldsWeDontNeed.firstName = true;
            fieldsWeDontNeed.lastName = true;
            fieldsWeDontNeed.companyName = true;
            fieldsWeDontNeed.numberOfEmployees = true;
            fieldsWeDontNeed.countrySearchControl = true;
            fieldsWeDontNeed.phoneNumber = true;
            fieldsWeDontNeed.websiteUrl = true;
          }

          for (const [field, alreadyHave] of Object.entries(fieldsWeDontNeed)) {
            this.formFieldEnabled[field].next(!alreadyHave);
          }
          this.formChangingRequirements$$.next(false);
        }),
        takeUntil(this.destroyed$$),
      )
      .subscribe();
  }

  private enableDisableField(name: string, enabled: boolean): void {
    const fieldControl = this.form.get(name);
    if (enabled) {
      fieldControl.enable();
    } else {
      fieldControl.disable();
    }
    fieldControl.updateValueAndValidity();
  }

  private syncFieldsEnabled$$ToFormControls(): void {
    for (const [name, enabled$$] of Object.entries(this.formFieldEnabled)) {
      enabled$$
        .pipe(
          distinctUntilChanged(),
          tap((enabled) => this.enableDisableField(name, enabled)),
          takeUntil(this.destroyed$$),
        )
        .subscribe();
    }
  }

  private handleQueryParams(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.params = params;
      const ssoState = SSOState.newSSOStateFromState(params.state);
      this.configureInitialState(params, ssoState);
      if (params?.code) {
        if (this.signupType === SIGNUP_TYPES.google) {
          this.googleOptionSelected$$.next(true);
          this.signUpService.getGoogleProfile(params.code).subscribe((resp) => {
            this.useGoogleAsProvider(resp.email, resp.accessToken);
          });
        } else if (this.signupType === SIGNUP_TYPES.microsoft) {
          this.microsoftOptionSelected$$.next(true);
          this.signUpService.getMicrosoftProfile(params.code).subscribe((resp: IDTokenEmail) => {
            this.useMicrosoftAsProvider(resp.email, resp.idToken);
          });
        } else if (this.signupType === SIGNUP_TYPES.linkedin) {
          this.linkedOptionSelected$$.next(true);
          this.signUpService.getLinkedInProfile(params.code).subscribe((resp: AccessTokenEmail) => {
            this.useLinkedInAsProvider(resp.email, resp.accessToken);
          });
        }
        const tree = this.router.createUrlTree([], {
          queryParams: this.removeSSOParamsAndAddSSOStateParams(params, ssoState),
        });
        window.history.replaceState(null, '', this.serializer.serialize(tree));
      }

      if (params?.googleToken) {
        let decodedToken: { email?: string } = {};
        try {
          decodedToken = jwt_decode(params.googleToken);
        } catch (e) {
          console.error('Unable to decode', e);
        }

        const email = decodedToken.email || '';
        this.isGoogleOneTap = true;
        this.useGoogleAsProvider(email, params.googleToken);
      }
    });
  }

  private removeSSOParamsAndAddSSOStateParams(params: Params, ssoState: SSOState): Params {
    const ssoParams = ['code', 'state', 'scope', 'authuser', 'prompt'];
    const newParams: Params = {};
    Object.entries(params)
      .filter(([key]) => !ssoParams.includes(key))
      .forEach(([key, value]) => (newParams[key] = value));
    Object.entries(ssoState)
      .filter(([key, value]) => !!value && key !== 'signuptype')
      .forEach(([key, value]) => (newParams[key] = value));
    return newParams;
  }

  private loginAndRedirectUser(partnerId: string, email: string, password: string): void {
    this.continuingToPlatform = true;
    const nextURL = this.getNextURL();
    this.signUpService
      .login(partnerId, email, password, nextURL)
      .pipe(take(1))
      .subscribe(
        (redirectUrl) => {
          this.continuingToPlatform = false;
          window.location.href = redirectUrl;
        },
        (err) => {
          this.continuingToPlatform = false;
          this.handleLoginErrors(err);
        },
      );
  }

  private handleSignUpErrors(error: any): void {
    const invalidArgument = 3;
    const preconditionFailed = 9;

    this.loading = false;
    if (error.error?.statusCode === 409) {
      this.snackbarService.openErrorSnack(this.translateService.instant('SIGNUP.VALIDATION.EMAIL_EXISTS'));
    } else if (error.error?.statusCode === 400) {
      this.snackbarService.openErrorSnack(error.error.message);
      // The code below is necessary for displaying errors for new self sign up request
    } else if (error.error?.code === invalidArgument) {
      this.snackbarService.openErrorSnack(error.error.message);
      // This error should only happen for sign ups that use recaptchas
    } else if (error.error?.code === preconditionFailed) {
      if (this.renderRecaptchaV2) {
        this.snackbarService.openErrorSnack(error.error.message);
      } else {
        this.loadRecaptchaV2();
      }
    } else {
      console.error(error);
      this.snackbarService.openErrorSnack(this.translateService.instant('COMMON.ERROR_TRY_AGAIN'));
    }
    this.captchaRef?.reset();
  }

  private handleLoginErrors(error: any): void {
    if (error.status === 412 && error.error?.message.includes('email_validation_required')) {
      this.snackbarService.openErrorSnack(this.translateService.instant('SIGNUP.VALIDATION.EMAIL_UNVERIFIED'));
    } else {
      console.error(error);
      this.snackbarService.openErrorSnack(this.translateService.instant('COMMON.ERROR_TRY_AGAIN'));
    }
  }

  private signUpSubmitTracking(): void {
    this.trackingService.trackEvent('SelfSignUp:submit-sign-up-form', 'SelfSignUp', 'submit', 0, {
      isGoogleSignUp: this.googleOptionSelected$$.value,
      isMicrosoftSignUp: this.microsoftOptionSelected$$.value,
      isLinkedInSignUp: this.linkedOptionSelected$$.value,
      isExistingAccountGroup: this.isExistingAccountGroup$$.value,
      isStandardSignUp:
        !this.googleOptionSelected$$.value &&
        !this.microsoftOptionSelected$$.value &&
        !this.linkedOptionSelected$$.value,
      renderedRecaptchaV2: this.renderRecaptchaV2,
      renderedRecaptchaV3: !this.renderRecaptchaV2,
      leadSource: this.leadSource,
    });
  }

  /**
   * Affiliate link tracking with PartnerStack
   * https://docs.partnerstack.com/docs/partnerstackjs
   *
   * @param email
   * @param name
   */
  private trackAffiliateSignup(partnerId: string): void {
    const email = this.form.get('email').value;
    const name = this.form.get('firstName').value + ' ' + this.form.get('lastName').value;
    const growsumo = window['growsumo'];
    if (growsumo) {
      growsumo.data.name = name;
      growsumo.data.email = email;
      growsumo.data.customer_key = partnerId;
      growsumo.createSignup();
    }
  }

  private runRecaptchaV3(): void {
    this.recaptchaV3Service
      .execute('submitSignUp')
      .pipe(take(1))
      .subscribe((token) => this.signUpRequestCameBackThroughRecaptcha(token, true));
  }

  private useGoogleAsProvider(email: string, googleToken: string): void {
    if (!email || !googleToken) {
      return;
    }

    this.googleOptionSelected$$.next(true);
    this.form.get('email').setValue(email);
    this.googleToken = googleToken;
    this.checkGoogleEmail(email);
  }

  private useMicrosoftAsProvider(email: string, microsoftToken: string): void {
    if (!email || !microsoftToken) {
      return;
    }

    this.microsoftOptionSelected$$.next(true);
    this.form.get('email').setValue(email);
    this.microsoftToken = microsoftToken;
    this.checkMicrosoftEmail(email);
  }

  private useLinkedInAsProvider(email: string, token: string): void {
    if (!email || !token) {
      return;
    }

    this.linkedOptionSelected$$.next(true);
    this.form.get('email').setValue(email);
    this.linkedInToken = token;
    this.checkEmail();
  }

  private signInTracking(): void {
    this.trackingService.trackEvent('SelfSignUp:social-sign-in', 'SelfSignUp', 'social-sign-in', 0, {
      isGoogleSignIn: this.googleOptionSelected$$.value,
      isMicrosoftSignIn: this.microsoftOptionSelected$$.value,
      isLinkedInSignIn: this.linkedOptionSelected$$.value,
      isGoogleOneTap: this.isGoogleOneTap,
      isMicrosoftOneTap: this.isMicrosoftOneTap,
    });
  }

  ngOnInit(): void {
    this.initializeForm();
    this.changeFormRequirements();
    this.syncFieldsEnabled$$ToFormControls();
    this.handleQueryParams();

    this.recaptchaSiteKey = this.signUpService.recaptchaSiteKey();

    this.currentStepService.reachedSignupLoadingScreen$
      .pipe(
        tap((complete) => {
          if (complete) {
            if (this.renderRecaptchaV2) {
              this.captchaRef.execute();
            } else {
              this.runRecaptchaV3();
            }
          }
        }),
        takeUntil(this.destroyed$$.asObservable()),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  private configureInitialState(params: Params, ssoState: SSOState): void {
    this.configureParams(params, ssoState);
    this.configureNextUrl(params, ssoState);
  }

  private configureParams(params: Params, ssoState: SSOState): void {
    const expiry = new Date();
    expiry.setFullYear(expiry.getFullYear() + 2);

    const marketingContentId = ssoState.marketingContentId || params.marketingContentId;

    this.conversionPoint =
      ssoState.conversion_point ||
      params?.conversion_point ||
      CustomContent.getNoDefaultConversionPoint(marketingContentId) ||
      '';
    if (this.conversionPoint) {
      this.cookieService.set(CONVERSION_POINT_KEY, this.conversionPoint, expiry);
    } else {
      this.conversionPoint = this.cookieService.get(CONVERSION_POINT_KEY) || CustomContent.getDefaultConversionPoint();
    }

    this.conquerLocalVisitorID = ssoState.visitor_id || params?.visitor_id || '';
    if (this.conquerLocalVisitorID) {
      this.cookieService.set(CONQUER_LOCAL_VISITOR_ID_KEY, this.conquerLocalVisitorID, expiry);
    } else {
      this.conquerLocalVisitorID = this.cookieService.get(CONQUER_LOCAL_VISITOR_ID_KEY);
    }
    this.prospectTags = '';
    if (params?.variant && TAGS_BY_VARIANT_TYPE[params.variant.toLowerCase()]) {
      this.prospectTags = TAGS_BY_VARIANT_TYPE[params.variant.toLowerCase()];
    }

    if (params?.['prospect-tags']) {
      this.prospectTags = params?.['prospect-tags'];
    }

    if (this.prospectTags) {
      this.cookieService.set(PROSPECT_TAGS_KEY, this.prospectTags, expiry);
    } else {
      this.prospectTags = this.cookieService.get(PROSPECT_TAGS_KEY);
    }

    if (params?.ref) {
      this.cookieService.set(SIGNUP_REFERRER_ID_KEY, params.ref, expiry);
    }

    if (ssoState.signuptype) {
      this.signupType = ssoState.signuptype;
    }

    const leadSource = ssoState.marketingContentId || params?.marketingContentId || params?.leadSource;
    if (leadSource) {
      this.leadSource = leadSource;
    }

    const leadDescription = ssoState.leadDescription || params?.leadDescription;
    if (leadDescription) {
      this.leadDescription = ssoState.leadDescription;
    }
  }

  private configureNextUrl(params: Params, ssoState: SSOState): void {
    if (ssoState.nextUrl) {
      this.nextUrl = ssoState.nextUrl;
    } else if (params?.nextUrl) {
      this.nextUrl = params.nextUrl;
    } else {
      this.nextUrl = '';
      this.nextUrlIsValid = false;
    }
    if (this.nextUrl.length) {
      this.nextUrlIsValid = !!NEXT_URLS.find((url) => this.nextUrl.includes(url));
    }
  }

  onSubmitStepTwo(): void {
    this.goToNextStep();
  }

  loadRecaptchaV2(): void {
    // Go back to the previous step when changing to v2, so that if it fails, information can be changed before retrying the form
    this.goToPreviousStep();
    this.renderRecaptchaV2 = true;
    this.changeDetector.detectChanges();
    this.captchaRef.execute();
  }

  resolvedRecaptchaV2(event: string): void {
    if (this.form.invalid) {
      return;
    }
    this.goToNextStep();
    this.signUpRequestCameBackThroughRecaptcha(event, false);
  }

  signUpRequestCameBackThroughRecaptcha(event: string, isRecaptchaV3: boolean): void {
    if (this.loading) {
      return;
    }
    if (!event) {
      this.goToPreviousStep();
      return;
    }
    this.signUpSubmitTracking();
    this.loading = true;
    this.signUp(event, isRecaptchaV3);
  }

  getBusinessNameFromForm(): string {
    const companyNameValue = this.form.get('companyName').value;
    if (this.companyAddressDetails.name) {
      return this.companyAddressDetails.name;
    } else if (companyNameValue?.mainText) {
      return companyNameValue.mainText;
    } else {
      return companyNameValue as string;
    }
  }

  goToNextStep(): void {
    const displayedSteps = new Map();
    for (const [key, subject] of Object.entries(this.formFieldEnabled)) {
      displayedSteps.set(key, subject.value);
    }
    this.currentStepService.incrementStep(Object.fromEntries(displayedSteps));
  }

  private goToPreviousStep(): void {
    this.currentStepService.decrementStep();
  }

  private emailInUseValidator(control: AbstractControl): Observable<ValidationErrors | null> {
    this.recaptchaV2isEmailInUse = false;
    return timer(TYPING_DEBOUNCE_MS).pipe(
      switchMap(() => {
        return this.recaptchaV3Service.execute('isEmailInUse');
      }),
      switchMap((captchaToken) => this.signUpService.isEmailInUse(control.value, captchaToken, true)),
      tap((response) => this.isExistingAccountGroup$$.next(response.emailInUse && response.persona === 'smb')),
      map((response) => {
        if (!response.emailInUse) {
          return null;
        }
        if (response.persona === 'salesperson' || response.persona === 'digital-agent') {
          return { emailInUse: true };
        }
        if (response.persona === 'partner' && !this.linkedOptionSelected$$.value) {
          return { emailInUsePartner: true };
        }
        return null;
      }),
      catchError((err) => {
        if (err?.error?.code === 9 || err?.error?.code === 412) {
          this.recaptchaV2isEmailInUse = true;
          return of(null);
        }
        return of({ unknownError: true });
      }),
    );
  }

  private disposableEmailValidator(control: AbstractControl): Observable<ValidationErrors | null> {
    return timer(TYPING_DEBOUNCE_MS).pipe(
      switchMap(() => {
        return this.recaptchaV3Service.execute('isDisposableEmail');
      }),
      switchMap((captchaToken) => this.signUpService.isEmailDisposable(control.value, captchaToken, true)),
      map((isDisposable) => {
        if (isDisposable) {
          return { disposableEmail: true };
        }
        return null;
      }),
      catchError((err) => {
        if (err?.error?.code === 9 || err?.error?.code === 412) {
          return of(null);
        }
        return of({ unknownError: true });
      }),
    );
  }

  checkEmail(): void {
    const email = this.form.get('email')?.value;

    this.recaptchaV3Service
      .execute('isEmailInUse')
      .pipe(
        switchMap((captchaToken) => {
          return this.signUpService.isEmailInUse(email, captchaToken, true);
        }),
        tap((isEmailInUseResponse) => {
          if (!isEmailInUseResponse.emailInUse) {
            this.goToNextStep();
          } else {
            const emailControl = this.form.get('email');
            emailControl.markAsTouched();
            emailControl.markAsDirty();

            if (isEmailInUseResponse.persona === 'partner') {
              if (this.linkedOptionSelected$$.value) {
                this.linkedInSignIn(this.linkedInToken, null, this.getNextURL());
              }
            }
          }
        }),
        take(1),
      )
      .subscribe({ error: () => this.snackbarService.openErrorSnack('SIGNUP.VALIDATION.UNKNOWN_ERROR') });
  }

  checkGoogleEmail(userEmail: string): void {
    this.recaptchaV3Service
      .execute('isEmailInUse')
      .pipe(
        switchMap((captchaToken) => {
          return this.signUpService.isEmailInUse(userEmail, captchaToken, true);
        }),
        tap((response) => {
          if (!response.emailInUse) {
            this.goToNextStep();
          } else {
            if (response.persona !== 'smb') {
              this.googleSignIn(this.googleToken, null, this.getNextURL());
            } else {
              this.isExistingAccountGroup$$.next(true);
              if (this.renderRecaptchaV2) {
                this.captchaRef.execute();
              } else {
                this.runRecaptchaV3();
              }
            }
          }
        }),
        take(1),
      )
      .subscribe({ error: () => this.snackbarService.openErrorSnack('SIGNUP.VALIDATION.UNKNOWN_ERROR') });
  }

  checkMicrosoftEmail(userEmail: string): void {
    this.recaptchaV3Service
      .execute('isEmailInUse')
      .pipe(
        switchMap((captchaToken) => {
          return this.signUpService.isEmailInUse(userEmail, captchaToken, true);
        }),
        tap((response) => {
          if (!response.emailInUse) {
            this.goToNextStep();
          } else {
            if (response.persona !== 'smb') {
              this.microsoftSignIn(this.microsoftToken, null, this.getNextURL());
            } else {
              this.isExistingAccountGroup$$.next(true);
              if (this.renderRecaptchaV2) {
                this.captchaRef.execute();
              } else {
                this.runRecaptchaV3();
              }
            }
          }
        }),
        take(1),
      )
      .subscribe({ error: () => this.snackbarService.openErrorSnack('SIGNUP.VALIDATION.UNKNOWN_ERROR') });
  }

  signUp(recaptchaCode: string, isRecaptchaV3: boolean): void {
    const email = this.form.get('email').value;
    const password = this.form.get('password').value;
    const request = this.buildSignUpRequest(recaptchaCode, isRecaptchaV3, email, password);
    const nextURL = this.getNextURL();

    this.nextURLForEmailVerification$
      .pipe(
        switchMap((nextUrlForEmailVerification) => {
          request.nextUrl = nextUrlForEmailVerification;
          return this.signUpService.signUp(request);
        }),
        map((partnerId) => partnerId as string),
        tap((partnerId: string) => {
          this.emailSentTo = email;
          this.createdPartnerId = partnerId;
          this.password = password;
          this.trackAffiliateSignup(partnerId);
          // Users should be verified through third party signin
          if (this.googleOptionSelected$$.value) {
            this.googleSignIn(this.googleToken, partnerId, nextURL);
            return;
          }
          if (this.microsoftOptionSelected$$.value) {
            this.microsoftSignIn(this.microsoftToken, partnerId, nextURL);
            return;
          }
          if (this.linkedOptionSelected$$.value) {
            this.linkedInSignIn(this.linkedInToken, partnerId, nextURL);
            return;
          }
          // Otherwise, we require them to verify their email so show a page where they are told to go to their email
          this.goToNextStep();
        }),
        take(1),
      )
      .subscribe({ error: (err) => this.handleSignUpErrors(err) });
  }

  googleSignIn(googleToken: string, partnerID: string, nextURL: string): void {
    this.signUpService
      .googleSignin(googleToken, partnerID, nextURL)
      .pipe(
        tap(() => this.signInTracking()),
        take(1),
      )
      .subscribe(
        (redirectUrl) => (window.location.href = redirectUrl),
        (err) => this.handleSignUpErrors(err),
      );
  }

  microsoftSignIn(microsoftToken: string, partnerID: string, nextURL: string): void {
    this.signUpService
      .microsoftSignin(microsoftToken, partnerID, nextURL)
      .pipe(
        tap(() => this.signInTracking()),
        take(1),
      )
      .subscribe(
        (redirectUrl) => (window.location.href = redirectUrl),
        (err) => this.handleSignUpErrors(err),
      );
  }

  linkedInSignIn(linkedInToken: string, partnerID: string, nextUrl: string): void {
    this.signUpService
      .linkedInSignin(linkedInToken, partnerID, nextUrl)
      .pipe(
        tap(() => this.signInTracking()),
        take(1),
      )
      .subscribe(
        (redirectUrl) => (window.location.href = redirectUrl),
        (err) => this.handleSignUpErrors(err),
      );
  }

  getNextURL(): string {
    return this.nextUrlIsValid ? this.nextUrl : '/user-onboarding';
  }

  submitUseCaseQuestion(): void {
    if (!this.useCaseQuestionControl.invalid) {
      this.goToNextStep();
    }
  }

  resendVerificationEmail(): void {
    this.resendingVerificationEmail = true;

    // Assuming at this step reCaptcha v3 would function properly, even when reCaptcha v2 was used in the previous step
    // If the verification email send fails, attempting to log in or reset password would send another
    this.recaptchaV3Service
      .execute('send_email_verification')
      .pipe(
        take(1),
        switchMap((token) => this.handleResendVerificationEmail(token)),
      )
      .subscribe(
        () => {
          this.resendingVerificationEmail = false;
          this.snackbarService.openSuccessSnack(
            this.translateService.instant('SIGNUP.SETUP.VERIFY_EMAIL_STEP.RESENT_VERIFICATION_EMAIL'),
          );
        },
        () => {
          this.resendingVerificationEmail = false;
          this.snackbarService.openErrorSnack(this.translateService.instant('COMMON.ERROR_TRY_AGAIN'));
        },
      );
  }

  private handleResendVerificationEmail(token: string): Observable<boolean> {
    return this.nextURLForEmailVerification$
      .pipe(
        take(1),
        switchMap((nextUrl) =>
          this.signUpService.sendEmailVerificationEmail(this.emailSentTo, this.createdPartnerId, nextUrl, '', token),
        ),
      )
      .pipe(take(1));
  }

  continueToPlatform(): void {
    this.loginAndRedirectUser(this.createdPartnerId, this.emailSentTo, this.password);
  }

  private initializeForm() {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl(
        '',
        [Validators.required, Validators.email, vendastaEmailValidator],
        [this.emailInUseValidator.bind(this), this.disposableEmailValidator.bind(this)],
      ),
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
      companyName: new UntypedFormControl('', [Validators.required]),
      numberOfEmployees: new UntypedFormControl('', [Validators.required]),
      countrySearchControl: new UntypedFormControl('', [Validators.required]),
      phoneNumber: new UntypedFormControl('', [Validators.required]),
      websiteUrl: new UntypedFormControl('', []),
    });

    this.updateEmailValidators();
  }

  private updateEmailValidators(): void {
    const emailValidators = [Validators.required, Validators.email, vendastaEmailValidator];
    if (this.environment.getEnvironment() !== Environment.DEMO) {
      emailValidators.push(noVendastaEmails);
    }

    const emailControl = this.form.get('email');
    if (emailControl) {
      emailControl.setValidators(emailValidators);
      emailControl.updateValueAndValidity();
    }
  }
}
