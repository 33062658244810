import { Params } from '@angular/router';

export class SSOState {
  signuptype: string;
  marketingContentId: string;
  leadDescription: string;
  nextUrl: string;
  conversion_point: string;
  visitor_id: string;

  static newSSOStateFromState(b64State: string): SSOState {
    const state = new SSOState();
    const queryParams = new URLSearchParams(atob(b64State || ''));

    state.signuptype = queryParams.get('signuptype');
    state.marketingContentId = queryParams.get('marketingContentId');
    state.leadDescription = queryParams.get('leadDescription');
    state.nextUrl = queryParams.get('nextUrl');
    state.conversion_point = queryParams.get('conversion_point');
    state.visitor_id = queryParams.get('visitor_id');

    return state;
  }

  static getQueryParamsWithSSO(params: Params): Params {
    const ssoState = SSOState.newSSOStateFromState(params.state);
    const queryAndSso = { ...params };
    Object.entries(ssoState)
      .filter(([, value]) => !!value)
      .forEach(([field, value]) => (queryAndSso[field] = value));
    return queryAndSso;
  }
}
