import { EnvironmentProviders, Provider } from '@angular/core';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import { MAT_CARD_CONFIG } from '@angular/material/card';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

export const GalaxyDefaultProviderOverrides: (Provider | EnvironmentProviders)[] = [
  {
    // Vendasta design settled on "outlined" as the default appearance for cards,
    // but the angular default is "raised".
    provide: MAT_CARD_CONFIG,
    useValue: { appearance: 'outlined' },
  },
  {
    // We want our checkboxes to be primary colored by default.
    provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  },
  {
    // We want our radio buttons to be primary colored by default.
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  },
  {
    // We want our slide toggles to be primary colored by default.
    provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  },
  {
    // The Mat tabs animation can look very chunky/stuttery when switching tabs if the tabs
    // have a lot of content. Disabling the animation makes the UI feel faster. The default
    // animationDuration is '500ms'
    //
    // In the Material 15 update, tabs stretch by default. This is a problem
    // as it can look weird when there are only a few tabs which take up the whole
    // width of the tab group. In Material 16, we will be able to override this behavior.
    provide: MAT_TABS_CONFIG,
    useValue: {
      animationDuration: '0ms',
      stretchTabs: false,
    },
  },
  {
    // Mat tooltip recently changed its default behavior and now allows you to
    // interact with the tooltip by default. This causes the tooltip to get in the
    // way of elements under it (like butons) and can be a hassle.
    // Disabling this new behavior makes the tooltip behave like it did before.
    provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
    useValue: { disableTooltipInteractivity: true },
  },
  {
    // Vendasta design settled on "outline" as the default appearance for form fields,
    // but the angular default is "fill". This rule overrides the default to be "outline".
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: { appearance: 'outline' },
  },
  {
    // Don't show indicator ✓ on button toggles by default
    provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
    useValue: { hideMultipleSelectionIndicator: true, hideSingleSelectionIndicator: true },
  },
];
