import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

export interface Script {
  name: string;
  src: string;
}

@Injectable()
export class StripeService {
  private loaded = false;
  public loadScript(): Observable<Script> {
    const script = {
      name: 'stripe',
      src: 'https://js.stripe.com/v3/',
    };
    return new Observable<Script>((observer: Observer<Script>) => {
      if (this.loaded) {
        observer.next(script);
        observer.complete();
      } else {
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = script.src;
        scriptElement.onload = () => {
          this.loaded = true;
          observer.next(script);
          observer.complete();
        };

        scriptElement.onerror = () => {
          observer.error("Couldn't load script " + script.src);
        };

        document.getElementsByTagName('body')[0].appendChild(scriptElement);
      }
    });
  }
}
