<div class="self-signup-container">
  <div class="fixed-top-section">
    <img alt="Vendasta" src="../assets/images/vendasta-logo.png" width="160" height="24" />
  </div>

  <div class="self-signup-content">
    <app-sign-up-form #signUpForm [loginURL]="loginURL$ | async"></app-sign-up-form>
  </div>

  <div *ngIf="(showLogin$ | async) === true" class="login-container">
    <span class="login-text">
      {{ 'SIGNUP.ALREADY_HAVE_AN_ACCOUNT' | translate }}
    </span>
    <a data-action="have-an-account-sign-in" class="login-link" [attr.href]="loginURL$ | async">
      {{ 'SIGNUP.SIGN_IN' | translate }}
    </a>
  </div>
</div>
