export interface CustomContentValue {
  formTitle?: string;
  formSubtitle?: string;
  conversionPoint?: string;
}

const freeTrialObj = {
  DEFAULT: {
    formTitle: 'SIGNUP.SETUP.STEP_ONE_TRIAL.TRIAL_TITLE',
    formSubtitle: 'SIGNUP.SETUP.STEP_ONE_TRIAL.SUBTITLE',
  },
};

const values: { [key: string]: CustomContentValue } = {
  DEFAULT: {
    formTitle: 'SIGNUP.SETUP.STEP_ONE_TRIAL.TRIAL_TITLE',
    formSubtitle: 'SIGNUP.SETUP.STEP_ONE_TRIAL.SUBTITLE',
    conversionPoint: 'SelfSignUp',
  },
};

export class CustomContent {
  static getValue(marketingContentId: string, flagShowFreeTrialFlow = false): CustomContentValue {
    const returnValue = {
      ...values.DEFAULT,
      ...values[marketingContentId],
    };

    // Show free trial message only for free trial flow.
    const showFreeTrialFlow =
      (flagShowFreeTrialFlow && marketingContentId !== 'free') || marketingContentId === 'freetrial';
    if (showFreeTrialFlow) {
      return {
        ...returnValue,
        ...freeTrialObj.DEFAULT,
      };
    }

    return returnValue;
  }

  /**
   * This function doesnt return a default value to give a chance to retrieve the conversion
   * point from cookies
   * @returns
   */
  static getNoDefaultConversionPoint(marketingContentId: string): string {
    return values[marketingContentId]?.conversionPoint;
  }

  static getDefaultConversionPoint(): string {
    return values.DEFAULT.conversionPoint;
  }
}
