import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthApiService, AuthService } from './auth';
import { CookieService } from './cookie.service';
import { throwIfAlreadyLoaded } from './guards';

@NgModule({
  imports: [],
  providers: [AuthApiService, AuthService, CookieService],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // CoreModule should only be imported in Root module.
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
