import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EnvironmentService, Environment } from '@galaxy/core';

@Injectable()
export class AuthApiService {
  constructor(private http: HttpClient, private environmentService: EnvironmentService) {}

  get host(): string {
    switch (this.environmentService.getEnvironment()) {
      case Environment.LOCAL:
        return 'https://partner-central-demo.appspot.com';
      case Environment.DEMO:
        return 'https://partner-central-demo.appspot.com';
      case Environment.PROD:
        return 'https://partners.vendasta.com';
      default:
        return 'https://partner-central-demo.appspot.com';
    }
  }

  public login(email: string, password: string, nextUrl?: string): Observable<string> {
    const body = {
      email: email,
      password: password,
      continue: nextUrl || '',
    };
    return this.http
      .post<{ data: { sso_url: string } }>(this.host + '/_ajax/v1/login', body, { headers: {} })
      .pipe(map((response) => response.data.sso_url));
  }
}
