<div class="sign-up-form-container">
  <div class="sign-up-form">
    <div class="sign-up-form-swiper-container">
      <div #formContainer class="sign-up-form-swiper" [formGroup]="form">
        <div
          class="sign-up-form-swiper-item small-container"
          *ngIf="showFirstStep"
          [class.show-step]="(currentStep$ | async) === SIGNUP_PROVIDER_STEP"
        >
          <app-sign-up-step-one
            [loginURL]="loginURL"
            [nextUrl]="nextUrl"
            [customContent$$]="customContent$$"
            [form]="form"
            [recaptchaV2isEmailInUse]="recaptchaV2isEmailInUse"
            [captchaRef]="captchaRef"
            [recaptchaSiteKey]="recaptchaSiteKey"
            [formFieldEnabled]="formFieldEnabled"
          ></app-sign-up-step-one>
        </div>

        <div
          class="sign-up-form-swiper-item small-container"
          [class.show-step]="
            (currentStep$ | async) === ACCOUNT_INFO_STEP_ONE || (currentStep$ | async) === ACCOUNT_INFO_STEP_TWO
          "
        >
          <app-sign-up-step-two-form
            [form]="form"
            [formFieldEnabled]="formFieldEnabled"
            [companyAddressDetails]="companyAddressDetails"
            [countryControl]="countryControl"
            [twoStepFormEnabled]="twoFormStepEnabled"
            (submitStepTwo)="onSubmitStepTwo()"
          >
          </app-sign-up-step-two-form>
        </div>
        <!--        <div-->
        <!--          class="sign-up-form-swiper-item small-container"-->
        <!--          [class.show-step]="(currentStep$ | async) === USE_CASE_QUESTION_STEP"-->
        <!--        >-->
        <!--          <ng-template [ngTemplateOutlet]="useCaseQuestion"></ng-template>-->
        <!--        </div>-->
        <div
          class="sign-up-form-swiper-item small-container"
          [class.show-step]="(currentStep$ | async) === SIGNUP_LOADING_SCREEN"
        >
          <ng-template [ngTemplateOutlet]="afterSignUpWaiting"></ng-template>
        </div>
        <div
          class="sign-up-form-swiper-item small-container"
          [class.show-step]="(currentStep$ | async) === VERIFY_EMAIL_STEP"
        >
          <ng-template [ngTemplateOutlet]="verifyEmailStep"></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<re-captcha
  *ngIf="renderRecaptchaV2"
  #captchaRef="reCaptcha"
  size="invisible"
  (resolved)="$event && resolvedRecaptchaV2($event)"
  [siteKey]="recaptchaSiteKey"
></re-captcha>

<ng-template #useCaseQuestion>
  <div class="sign-up-form-explanation sign-up-form-swiper-container">
    <div class="sign-up-form-explanation-title">
      {{ 'SIGNUP.SETUP.USE_CASE_QUESTION.TITLE' | translate }}
    </div>
    <glxy-form-field>
      <mat-radio-group required="true" [formControl]="useCaseQuestionControl">
        <mat-radio-button value="leads">{{ 'SIGNUP.SETUP.USE_CASE_QUESTION.OPTION_1' | translate }}</mat-radio-button>
        <mat-radio-button value="scale">{{ 'SIGNUP.SETUP.USE_CASE_QUESTION.OPTION_2' | translate }}</mat-radio-button>
      </mat-radio-group>
    </glxy-form-field>
    <div class="sign-up-form-actions">
      <button
        data-action="step-use-case-submit"
        class="sign-up-form-cta full-width"
        mat-flat-button
        color="primary"
        type="button"
        (click)="submitUseCaseQuestion()"
        data-testid="signup-button"
      >
        <ng-container>
          {{ 'SIGNUP.BUTTONS.NEXT' | translate }}
        </ng-container>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #afterSignUpWaiting>
  <div class="sign-up-form-explanation sign-up-form-swiper-container">
    <div class="sign-up-form-explanation-title">
      {{ 'SIGNUP.SETUP.AFTER_SIGNUP_WAITING.TITLE' | translate }}
    </div>
    <div class="sign-up-form-explanation-title">
      {{ 'SIGNUP.SETUP.AFTER_SIGNUP_WAITING.TITLE_MORE' | translate }}
    </div>
    <div class="sign-up-form-explanation-subtitle">
      {{ 'SIGNUP.SETUP.AFTER_SIGNUP_WAITING.SUBTITLE' | translate }}
    </div>
    <mat-progress-bar class="sign-up-progress" mode="indeterminate"></mat-progress-bar>
    <img src="../../assets/images/your-logo-here.png" class="your-logo-here" alt="Your Logo Here" />
  </div>
</ng-template>

<ng-template #verifyEmailStep>
  <div class="email-verification-form-explanation sign-up-form-swiper-container">
    <div class="email-verification-form-explanation-title">
      {{ 'SIGNUP.SETUP.VERIFY_EMAIL_STEP.TITLE' | translate }}
    </div>
    <p
      class="email-verification-form-explanation-subtitle"
      [innerHTML]="'SIGNUP.SETUP.VERIFY_EMAIL_STEP.SUBTITLE' | translate: { email: emailSentTo }"
    ></p>
    <p class="email-verification-form-explanation-subtitle">
      {{ 'SIGNUP.SETUP.VERIFY_EMAIL_STEP.FOLLOW_THE_LINK' | translate }}
    </p>
    <div class="email-verification-form-actions">
      <button
        data-action="continue-to-platform"
        class="email-verification-form-button"
        mat-flat-button
        color="primary"
        type="button"
        [disabled]="continuingToPlatform"
        (click)="continueToPlatform()"
        data-testid="continue-to-platform"
      >
        <glxy-button-loading-indicator [isLoading]="continuingToPlatform">
          {{ 'SIGNUP.SETUP.VERIFY_EMAIL_STEP.CONTINUE_TO_PLATFORM' | translate }}
        </glxy-button-loading-indicator>
      </button>
      <ng-template #buttonWithSpinner>
        <button mat-flat-button color="primary" class="email-verification-form-button">
          <span>
            <mat-progress-spinner mode="indeterminate" diameter="26"></mat-progress-spinner>
          </span>
        </button>
      </ng-template>
      <button
        data-action="resend-verification-email"
        class="email-verification-form-button"
        mat-stroked-button
        type="button"
        (click)="resendVerificationEmail()"
        [disabled]="resendingVerificationEmail"
        data-testid="resend-verification-email"
      >
        <glxy-button-loading-indicator [isLoading]="resendingVerificationEmail">
          {{ 'SIGNUP.SETUP.VERIFY_EMAIL_STEP.RESEND_BUTTON_TEXT' | translate }}
        </glxy-button-loading-indicator>
      </button>
      <ng-template #buttonWithSpinner>
        <button mat-flat-button color="primary" class="email-verification-form-button">
          <span>
            <mat-progress-spinner mode="indeterminate" diameter="26"></mat-progress-spinner>
          </span>
        </button>
      </ng-template>
    </div>
  </div>
</ng-template>
