import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {
  get(name: string): string {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }

  set(name: string, value: string, expires: Date): void {
    document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
  }
}
