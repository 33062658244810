import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take, tap } from 'rxjs/operators';

export interface DropdownItem {
  description: string;
  mainText: string;
  placeId: string;
  secondaryText: string;
}

interface Location {
  lat: number;
  lng: number;
}

export interface AutoCompleteCallback {
  setBusinessSelectedDetailsCallback(place_id: string, place: google.maps.places.PlaceResult): void;

  clearSelectedBusiness(): void;
}

const NONE_FOUND_SINGLE_TEXT = 'Use this name';
const NONE_FOUND_MULTIPLE_TEXT = 'Use this name or select your business above';

@Injectable({ providedIn: 'root' })
export class AutocompleteService {
  private static NO_BUSINESS_FOUND = '___pcc_no_business_found';
  static noBusinessChoice: DropdownItem = {
    mainText: '',
    description: '',
    secondaryText: NONE_FOUND_MULTIPLE_TEXT,
    placeId: AutocompleteService.NO_BUSINESS_FOUND,
  };
  map: google.maps.Map;
  delegate: AutoCompleteCallback;
  private autocompleteSessionToken: google.maps.places.AutocompleteSessionToken;
  private currentPosition: Location;

  constructor(public http: HttpClient) {}

  private static parseAutoCompleteResponse(
    responses: google.maps.places.AutocompletePrediction[],
    term: string,
  ): DropdownItem[] {
    const choices = [];
    if (responses) {
      for (let i = 0; i <= responses.length; i++) {
        if (responses[i]) {
          const drop_down: DropdownItem = AutocompleteService.convertToDropDown(responses[i]);
          choices.push(drop_down);
        }
      }
    }
    AutocompleteService.createNoChoiceOption(term, responses);
    AutocompleteService.noBusinessChoice.secondaryText = !responses ? NONE_FOUND_SINGLE_TEXT : NONE_FOUND_MULTIPLE_TEXT;
    choices.push(AutocompleteService.noBusinessChoice);
    return choices;
  }

  private static createNoChoiceOption(
    search_term: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    responses: google.maps.places.AutocompletePrediction[],
  ): void {
    const no_business_choice = AutocompleteService.noBusinessChoice;
    no_business_choice.mainText = search_term;
    no_business_choice.description = search_term;
  }

  private static convertToDropDown(response: google.maps.places.AutocompletePrediction): DropdownItem {
    return {
      mainText: response.structured_formatting.main_text,
      description: response.description,
      secondaryText: response.structured_formatting.secondary_text,
      placeId: response.place_id,
    };
  }

  public init(googleMap: google.maps.Map, delegate: AutoCompleteCallback): void {
    this.map = googleMap;
    this.delegate = delegate;

    this.http
      .post('https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyDxrR1bk6d2TRa4orIDF05H3UlhvIXRRC0', {})
      .pipe(
        tap((response: { location: Location }) => {
          if (response?.location) {
            this.currentPosition = response.location;
          }
        }),
        take(1),
      )
      .subscribe();

    this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
  }

  onSelect(placeId: string): void {
    if (placeId && placeId !== AutocompleteService.NO_BUSINESS_FOUND) {
      const placeService = new google.maps.places.PlacesService(this.map);
      const callback = (place: google.maps.places.PlaceResult): void => {
        this.delegate.setBusinessSelectedDetailsCallback(placeId, place);
      };
      placeService.getDetails(
        {
          placeId: placeId,
          fields: ['formatted_phone_number', 'name', 'address_component', 'website', 'types'],
          sessionToken: this.autocompleteSessionToken,
        },
        callback,
      );
    } else {
      this.delegate.clearSelectedBusiness();
    }
  }

  getChoices(search_term: string, suggest: (i: DropdownItem[]) => void): any {
    AutocompleteService.createNoChoiceOption(search_term, null);
    const service = new google.maps.places.AutocompleteService();
    const callback = (result: google.maps.places.AutocompletePrediction[]): void => {
      suggest(AutocompleteService.parseAutoCompleteResponse(result, search_term));
    };
    try {
      const request = {
        input: search_term.toLowerCase(),
        types: ['establishment'],
        sessionToken: this.autocompleteSessionToken,
      } as google.maps.places.AutocompletionRequest;
      if (this.currentPosition) {
        request.bounds = {
          north: this.currentPosition.lat + 0.5,
          east: this.currentPosition.lng + 0.5,
          south: this.currentPosition.lat - 0.5,
          west: this.currentPosition.lng - 0.5,
        } as google.maps.LatLngBoundsLiteral;
      }
      service.getPlacePredictions(request, callback);
    } catch (e) {
      console.error(e);
    }
  }
}
