import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';

import { AuthApiService } from './auth.api.service';
import { SessionService } from '@galaxy/core';

export interface JWT {
  exp: number;
  sub?: string;
}

@Injectable()
export class AuthService {
  private authToken$: Observable<string>;

  get decodedAuthToken$(): Observable<JWT> {
    return this.authToken$.pipe(map(this.decodeJWT));
  }

  get isAuthed$(): Observable<boolean> {
    return this.decodedAuthToken$.pipe(map(this.tokenIsValid));
  }

  constructor(private api: AuthApiService, private sessionService: SessionService) {
    this.authToken$ = this.sessionService.getSessionId();
  }

  /***
   * This will attempt to log the user in, and then it will return a url to redirect to upon success.
   *
   * This url will create a session in our login service. After which it will transfer this session to,
   * and redirect to, Partner Center.
   *
   * @param {string} email
   * @param {string} password
   * @param {string} nextUrl
   * @returns {Observable<string>} A url to redirect to.
   */
  public authenticate(email: string, password: string, nextUrl?: string): Observable<string> {
    return this.api.login(email, password, nextUrl);
  }

  private decodeJWT(jwt: string): JWT | null {
    let claims;
    try {
      claims = jwt_decode(jwt);
    } catch (err) {
      console.log('CLAIMS FAILED: ', err);
    }
    return claims;
  }

  private tokenIsValid(token: JWT): boolean {
    return !!token && new Date() < new Date(token.exp * 1000);
  }
}
