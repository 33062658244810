import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Environment, EnvironmentService } from '@galaxy/core';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductAnalyticsService } from '@vendasta/product-analytics';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CurrentStepService, SIGNUP_PROVIDER_STEP } from './currentstep.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

declare let deployment;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  displayID;

  showLogin$: Observable<boolean>;
  loginURL$: Observable<string>;

  @ViewChild('signUpForm') signUpForm: SignUpFormComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private snowplowTracker: ProductAnalyticsService,
    private environmentService: EnvironmentService,
    private translateService: TranslateService,
    private currentStepService: CurrentStepService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    if (typeof deployment !== 'undefined') {
      this.snowplowTracker.initialize({
        environment: this.environmentService.getEnvironment(),
        projectUUID: '11119d8d-4e3b-4ceb-a65b-625d9e59e83d',
        postHogID: 'oet8qnSAuaVxnNAB4uOIFnxOFnkOy8qOXZTDwsafErE',
        projectName: 'partner-self-signup-client',
      });
    }
    this.translateService.setDefaultLang('en');

    this.matIconRegistry.addSvgIcon(
      'light_bulb',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/bulb-3.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'multiple_people',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/multiple-11-4.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'new_construction',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/new-construction-2.svg'),
    );
  }

  ngOnInit(): void {
    this.showLogin$ = this.currentStepService.stepChange$.pipe(
      map((currentStep) => {
        return currentStep.current === SIGNUP_PROVIDER_STEP;
      }),
    );
    this.displayID = this.activatedRoute.snapshot.queryParamMap.get('marketingContentId');
    this.loginURL$ = this.activatedRoute.queryParams.pipe(
      map((params) => {
        if (params.marketingContentId === 'academy' && params.nextUrl) {
          return params.nextUrl;
        } else {
          return this.environmentService.getEnvironment() === Environment.PROD
            ? 'https://partners.vendasta.com/login/'
            : 'https://partner-central-demo.appspot.com/login/';
        }
      }),
    );
  }
}
