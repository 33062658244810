import { NgModule } from '@angular/core';
import { TrackEventDirective } from './track-event.directive';

export const MODULE_DECLARATIONS = [TrackEventDirective];
export const MODULE_EXPORTS = [TrackEventDirective];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  exports: MODULE_EXPORTS,
  providers: [],
})
export class ProductAnalyticsModule {}
