<div>
  <div class="sign-up-form-explanation sign-up-form-swiper-container">
    <div class="sign-up-form-explanation-title" data-testid="step-one-title">
      {{ (customContent$$ | async)?.formTitle | translate }}
    </div>
    <div class="sign-up-form-explanation-subtitle">
      {{ (customContent$$ | async)?.formSubtitle | translate }}
    </div>
  </div>
  <div>
    <div class="form-container">
      <div>
        <div *ngIf="(signUpVariant$$ | async) !== 'HACKERONE'">
          <div class="row">
            <span
              class="sign-up-buttons-container"
              *ngIf="
                (googleOptionSelected$$ | async) === false &&
                  (microsoftOptionSelected$$ | async) === false &&
                  (linkedOptionSelected$$ | async) === false;
                else buttonWithSpinner
              "
            >
              <button
                data-action="step-one-google-sign-in"
                type="button"
                class="google-sign-in"
                id="googleBtn"
                mat-icon-button
                (click)="googleSignUpSelection()"
                data-testid="sign-up-google"
              >
                <img src="../../../assets/images/icons/google-logo.png" class="google-logo" alt="Google logo" />
                <span class="google-sign-in-text">
                  {{ 'SIGNUP.BUTTONS.GOOGLE' | translate }}
                </span>
              </button>
              <button
                data-action="step-one-microsoft-sign-in"
                type="button"
                class="microsoft-sign-in"
                id="microsoftBtn"
                mat-icon-button
                (click)="microsoftSignUpSelection()"
                data-testid="sign-up-microsoft"
              >
                <img
                  src="../../../assets/images/icons/microsoft-logo.png"
                  class="microsoft-logo"
                  alt="microsoft logo"
                />
                <span class="google-sign-in-text">
                  {{ 'SIGNUP.BUTTONS.MICROSOFT' | translate }}
                </span>
              </button>
              <button
                data-action="step-one-linkedin-sign-in"
                type="button"
                class="linkedin-sign-in"
                id="linkedinBtn"
                mat-icon-button
                (click)="linkedinSignUpSelection()"
                data-testid="sign-up-linkedin"
              >
                <img
                  src="https://storage.googleapis.com/galaxy-libs-public-images/linkedin-bug.svg"
                  class="linkedin-logo"
                  alt="LinkedIn Logo"
                />
                <span class="linkedin-sign-in-text">
                  {{ 'SIGNUP.BUTTONS.LINKEDIN' | translate }}
                </span>
              </button>
            </span>
            <ng-template #buttonWithSpinner>
              <mat-progress-spinner mode="indeterminate" diameter="26"></mat-progress-spinner>
            </ng-template>
          </div>
          <div class="row sign-up-divider" [class.googleHide]="googleOptionSelected$$ | async">
            <div>
              {{ 'SIGNUP.OR' | translate }}
            </div>
          </div>
        </div>
        <div class="row" [class.googleHide]="googleOptionSelected$$ | async">
          <glxy-form-field appearance="outline" style="width: 100%">
            <glxy-label>{{ 'SIGNUP.EMAIL' | translate }}</glxy-label>
            <input
              #emailInput
              [placeholder]="'SIGNUP.EMAIL' | translate"
              [formControl]="form.get('email')"
              type="email"
              id="email"
              matInput
              email="true"
              name="email"
              required
              data-testid="email-input"
            />
            <glxy-error *ngIf="form.dirty && form.get('email')?.invalid" data-testid="email-error">
              <div *ngIf="form.get('email')?.errors?.unknownError">
                {{ 'SIGNUP.VALIDATION.UNKNOWN_ERROR' | translate }}
              </div>
              <div *ngIf="form.get('email')?.errors?.required">
                {{ 'COMMON.FIELD_REQUIRED' | translate: { fieldName: 'SIGNUP.EMAIL' | translate } }}
              </div>
              <div *ngIf="form.get('email')?.errors?.forbiddenEmail">
                {{ 'SIGNUP.VALIDATION.NO_VENDASTA_EMAIL' | translate }}
              </div>
              <div *ngIf="form.get('email')?.errors?.email || form.get('email')?.errors?.invalidEmail">
                {{ 'SIGNUP.VALIDATION.VALID_EMAIL' | translate }}
              </div>
              <div *ngIf="form.get('email')?.errors?.emailInUse">
                {{ 'SIGNUP.VALIDATION.EMAIL_IN_USE' | translate }}
              </div>
              <div
                *ngIf="form.get('email')?.errors?.emailInUsePartner"
                [innerHTML]="'SIGNUP.VALIDATION.EMAIL_IN_USE_PARTNER' | translate: { loginURL: loginURL }"
              ></div>
              <div *ngIf="form.get('email')?.errors?.disposableEmail">
                {{ 'SIGNUP.VALIDATION.DISPOSABLE_EMAIL' | translate }}
              </div>
              <div *ngIf="form.get('email')?.errors?.nonHackerOneEmail">
                {{ 'SIGNUP.VALIDATION.NON_HACKERONE_EMAIL' | translate }}
              </div>
            </glxy-error>
          </glxy-form-field>
        </div>
        <div class="sign-up-form-cta-container" [class.googleHide]="googleOptionSelected$$ | async">
          <re-captcha
            *ngIf="recaptchaV2isEmailInUse"
            class="sign-up-form-recaptchaV2"
            #captchaRef="reCaptcha"
            [siteKey]="recaptchaSiteKey"
            (resolved)="resolvedRecaptchaV2isEmailInUse($event)"
          ></re-captcha>
          <ng-container *ngIf="emailFormStatus$$ | async as emailFormStatus">
            <button
              data-action="step-one-email-sign-in"
              class="sign-up-form-cta full-width"
              mat-flat-button
              color="primary"
              type="button"
              [disabled]="emailFormStatus === 'PENDING'"
              (click)="onSubmitStepOne()"
              data-testid="email-or-google-next"
            >
              <glxy-button-loading-indicator [isLoading]="emailFormStatus === 'PENDING'">
                {{ 'SIGNUP.BUTTONS.NEXT' | translate }}
              </glxy-button-loading-indicator>
            </button>
          </ng-container>
        </div>
        <div class="sign-up-form-privacy-policy">
          {{ 'SIGNUP.TOS_TOS_PRELINK' | translate }}
          <a data-action="step-one-tos" href="https://www.vendasta.com/terms/terms-of-service/" target="_blank">
            {{ 'SIGNUP.TOS_TOS_LINK' | translate }}
          </a>
          <span class="link-separator">,</span>
          <a data-action="step-one-privacy" href="https://www.vendasta.com/privacy-policy" target="_blank">
            {{ 'SIGNUP.TOS_PRIVACY_POLICY_LINK' | translate }}
          </a>
          {{ 'SIGNUP.TOS_BETWEEN_LINKS' | translate }}
          <a data-action="step-one-cookie" href="https://www.vendasta.com/cookie-policy/" target="_blank">
            {{ 'SIGNUP.TOS_COOKIE_POLICY_LINK' | translate }}
          </a>
          <span class="link-separator">.</span>
          <span [innerHTML]="'SIGNUP.RECAPTCHA_BRANDING' | translate"></span>
        </div>
      </div>
    </div>
  </div>
</div>
